import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import dayjs from 'dayjs'
import _ from 'lodash'
import 'vant/lib/index.css'
import '@/styles/index.scss'
import '@/styles/utilities.scss'

Vue.config.productionTip = false

Vue.prototype.dayjs = dayjs
Vue.prototype._ = _

Vue.use(Vant)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

import axios from 'axios'
import router from '@/router'

const request = axios.create({
  baseURL: process.env.VUE_APP_API_CORE
  // timeout: 10000
})

request.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json'
  // 统一添加 source
  if (config.url.startsWith('http')) {
    return config
  }
  config.headers.channelId = localStorage.getItem('channelId') || ''
  config.headers.channelCode = localStorage.getItem('channelCode') || 'default'
  config.headers.province = encodeURIComponent(localStorage.getItem('province') || '')
  config.headers.city = encodeURIComponent(localStorage.getItem('city') || '')
  // 统一添加 token
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.token = token
  }
  return config
})

request.interceptors.response.use(
  (res) => {
    if (res.config.url.startsWith('http')) {
      return res.data
    }
    if (res.data.code === 10011 || res.data.code === 10010) {
      localStorage.removeItem('token')
      const channelCode = localStorage.getItem('channelCode') || 'default'
      router.push('/login?channelCode=' + channelCode)
    }
    return res.data
  },
  (err) => {
    // console.log(err)
    const res = {
      code: 500,
      data: ''
    }
    return res
  }
)

export default request

import request from '@/utils/request'

export const login = (data) => {
  return request({
    method: 'POST',
    url: '/auth/login',
    data
  })
}

export const sendSms = (data) => {
  return request({
    method: 'POST',
    url: '/auth/sendSms',
    data
  })
}

export const channelLog = () => {
  return request({
    method: 'POST',
    url: '/channel/log'
  })
}

export const channelAvailable = () => {
  return request({
    method: 'POST',
    url: '/channel/available'
  })
}

export const recommendProduct = () => {
  return request({
    method: 'GET',
    url: '/product/recommend'
  })
}

export const productLists = () => {
  return request({
    method: 'GET',
    url: '/product/list'
  })
}

export const historyList = () => {
  return request({
    method: 'GET',
    url: '/product/historyList'
  })
}

export const productClick = (data) => {
  return request({
    method: 'POST',
    url: '/product/click',
    data
  })
}

export const districts = () => {
  return request({
    method: 'GET',
    url: '/loanInfo/districts'
  })
}

export const loanInfoSubmit = (data) => {
  return request({
    method: 'POST',
    url: '/loanInfo/save',
    data
  })
}

export const userInfo = () => {
  return request({
    method: 'GET',
    url: '/user/info'
  })
}

export const feedback = (data) => {
  return request({
    method: 'POST',
    url: '/user/feedback',
    data
  })
}

export const getIp = () => {
  return request({
    method: 'POST',
    url: '/channel/getInfoByIP'
  })
}

export const getCompany = () => {
  return request({
    method: 'GET',
    url: '/index/companyName'
  })
}

export const pushResult = () => {
  return request({
    method: 'GET',
    url: '/push/result'
  })
}

export const saveBasicInfo = (data) => {
  return request({
    method: 'POST',
    url: '/loanInfo/saveBasicInfo',
    data
  })
}

export const saveRealName = (data) => {
  return request({
    method: 'POST',
    url: '/loanInfo/saveRealName',
    data
  })
}

export const saveQualification = (data) => {
  return request({
    method: 'POST',
    url: '/loanInfo/saveQualification',
    data
  })
}

export const applyResult = () => {
  return request({
    method: 'GET',
    url: '/push/applyResult'
  })
}

export const result = () => {
  return request({
    method: 'GET',
    url: '/push/result'
  })
}

export const allResult = () => {
  return request({
    method: 'GET',
    url: '/push/allResult'
  })
}

export const getInfo = (params) => {
  return request({
    method: 'GET',
    url: '/push/info',
    params: params
  })
}

export const getPlatformInfo = (data) => {
  return request({
    method: 'POST',
    url: '/push/platform_info',
    data
  })
}

export const getApply = (data) => {
  return request({
    method: 'POST',
    url: '/loanInfo/apply',
    data
  })
}

export const saveHtmlRealName = (data) => {
  return request({
    method: 'POST',
    url: '/loanInfo/saveHtmlRealName',
    data
  })
}

export const channelLinkageLogin = (data) => {
  return request({
    method: 'POST',
    url: `/product/channelLinkage/login/${data}`
  })
}

export const getLoanInfo = (userId) => {
  return request({
    method: 'GET',
    url: `/loanInfo/getUserInfo/${userId}`
  })
}
